import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import '../Assets/CSS/Settings.scss';
import {axiosPost} from "../Utility/httpClient";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

type ModalProps = {
    open: boolean;
    onClose: () => void;
}

const submitLogin = async (username: String, password: String): Promise<string> => axiosPost(`/auth/token`, {username: username, password: password})

export default function LoginModal(props: ModalProps) {
    const { onClose, open } = props;
    const dialogStyles = {
        backgroundColor: 'rgb(18, 28, 38)',
        color: 'white',
        padding: '25px',
        boxShadow: 'none',
        width: '600px',
        overflow: 'hidden',
        borderRadius: '10px',
        border: '1px solid rgb(43, 53, 64)'
    };

    const [username, setUsername] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={open} PaperProps={{ style: dialogStyles }}>
            <div className="Btcs-form Settings-Modal">
                <h2>Sign In</h2>
                <br />

                <h3 className="Field-title">Username</h3>

                <div className="Form-input-field">
                    <div className="Form-input">
                        <div className="Form-icon">
                            <ArrowUpward />
                            {/*<img src="/profile/shuffle.png"/>*/}
                        </div>
                        <input autoComplete="off" type="text" placeholder="Username..." defaultValue={username}
                               onChange={(e) => {
                                   setUsername(e.target.value)
                               }}/>
                    </div>
                </div>
                <br />

                <h3 className="Field-title">Password</h3>

                <div className="Form-input-field">
                    <div className="Form-input">
                        <div className="Form-icon">
                            {/*<img src="/profile/shuffle.png"/>*/}
                            <ArrowDownward />
                        </div>
                        <input autoComplete="off" type="password" placeholder="Password..." defaultValue={password}
                               onChange={(e) => {
                                   setPassword(e.target.value)
                               }}/>
                    </div>
                </div>
                <br />
                <div className="Form-submission">
                    <button onClick={() => {
                        if (!username || !password) {
                            return
                        }
                        submitLogin(username, password).then(r => {
                            localStorage.setItem("bearer", r)
                            window.location.reload()
                        })
                    }}>{'Login'}</button>
                </div>
            </div>
        </Dialog>
    );
}